import React from 'react';

class PrintUtil extends React.Component {

  static printLabel(label_path, params, copies = 1) {
    return new Promise((resolve, reject) => {     
      try {
        dymo.label.framework.getPrintersAsync().then((printers) => {
          if (printers.length == 0) {
            reject("No DYMO printers found");
            return;
          }

          var firstPrinter = printers[0];
          if(!firstPrinter.isConnected) {
            reject("DYMO printer is not connected");
            return;
          }

          var noCacheHeaders = new Headers();
          noCacheHeaders.append('pragma', 'no-cache');
          noCacheHeaders.append('cache-control', 'no-cache');
          
          var fetchParams = {
            method: 'GET',
            headers: noCacheHeaders,
          };

          fetch(label_path, fetchParams).then(content => {
            content.text().then(xml => {
              localStorage.setItem(label_path, xml);
              this.print(firstPrinter, xml, params, copies).then(() => {
                resolve();
              }).catch((error) => {
                console.log(error);
                reject("Unable to print label");
              });
            });
          }).catch((error) => {
            console.log(error);
            var xml = localStorage.getItem(label_path);
            if (xml) {
              this.print(firstPrinter, xml, params, copies).then(() => {
                resolve();
              }).catch((error) => {
                console.log(error);
                reject("Unable to print cached label");
              });
            } else {
              reject("Label file not found");
            }
          });
        }).thenCatch((error) => {
            reject(error);
        });
      } catch (error) {
        console.log(error);
        reject("Unable to connect to DYMO printer software");
      }
    });
  }

  static print(printer, xml, params, copies) {
    return new Promise((resolve, reject) => {
      try {
        var labelXml = dymo.label.framework.openLabelXml(xml);
        
        var builder = new dymo.label.framework.LabelSetBuilder();
        let labelRecord = builder.addRecord();
        for (let [key, value] of params) {
          labelRecord.setText(key, value);
        }
        
        var paramsXml = dymo.label.framework.createLabelWriterPrintParamsXml({ copies: copies });

        dymo.label.framework.printLabel(printer.name, paramsXml, labelXml, builder.toString());
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default PrintUtil;
