import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getAllInvoiceGroups, getInvoicesReport } from '../../../shared/services/invoice_service.js';
import { getAllAccounts } from '../../../shared/services/account_service';
import ErrorHandler from '../../../shared/util/error_handler.js';
import Converter from '../../../shared/util/converter.js';
import AsyncButton from '../../../shared/components/async_button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Mapper from '../../../shared/util/mapper';
import DomUtil from '../../../shared/util/dom_util.js';
import RowDataTable from '../../../shared/components/row_data_table';
import TableUtil from '../../../shared/util/table_util'

class InvoicesRevenueReport extends React.Component {

  constructor(props) {
    super(props)

    var default_selection = "this_year";

    this.state = {
      range: {
        selection: default_selection,
        from: this.getFrom(default_selection),
        to: this.getTo(default_selection)
      },
      accounts: [],
      invoice_groups: [],
      counterparty: undefined,
      invoices: []
    };
  }

  componentWillMount() {
    this.getAllAccounts();
    this.getAllInvoiceGroups();
  }

  getAllAccounts() {
    getAllAccounts().then((response) => {
      if (response) {
        this.setState({ accounts: response.accounts });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getAllInvoiceGroups() {
    getAllInvoiceGroups().then((response) => {
      if (response) {
        this.setState({ invoice_groups: response.invoice_groups });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  createReport(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    this.setState({ invoices: [] });
    
    getInvoicesReport(this.getCounterparty(),  undefined, this.state.range.from, this.state.range.to, this.state.show_all_in_group).then((invoices) => {
      if (invoices) {
        for(const invoice of invoices) {
          if(invoice.resource_type === 'shipment') {
            invoice.resource = "Shipment " + invoice.resource_id;
          }
          if(invoice.resource_type === 'invoice_group') {
            var invoice_group = this.state.invoice_groups.find(i => i.id === invoice.resource_id);
            if(invoice_group) {
              invoice.resource = invoice_group.name;
            } else {
              invoice.resource = "Invoice group " + invoice.resource_id.substring(0, 6);
            }
          }
        }
        
        this.setState({ invoices: invoices });
      }
      DomUtil.enableFormSubmitButtonClass(form);
    }).catch(error => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  getCounterparty() {
    if(this.state.counterparty) {
      return this.state.counterparty.id;
    }
  }

  handleRangeChange(e) {
    this.state.range.selection = e.target.value;
    this.state.range.from = this.getFrom(e.target.value);
    this.state.range.to = this.getTo(e.target.value);
    this.setState({ range: this.state.range });
  }

  handleDateChange(e) {
    this.state.range.selection = "custom";
    this.state.range[e.target.name] = e.target.value;
    this.setState({ range: this.state.range });
  }

  handleCounterpartySelectionChanged(e, newValue) {
    if(newValue) {
      this.setState({ counterparty: newValue });
    } else {
      this.setState({ counterparty: { id: undefined } });
    }
  }

  handleChange(property, e) {
    var value = e.target.value === "" ? undefined : e.target.value;
    this.setState({ [property]: value });
  }

  firstAccountLetter(option) {
    return option.name[0].toUpperCase();
  }

  getCounterpartyOptionLabel(value) {
    var counterparty;
    if(value.id) {
      counterparty = this.state.accounts.find(a => a.id === value.id);
    }
    if(!counterparty) {
      return "";
    }
    return counterparty.name;
  }

  getFrom(selection) {
    var now = new Date();
    var from = undefined;

    switch(selection) {
      case "this_month":
        from = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
      case "last_month":
        from = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        break;
      case "last_three_months":
        from = new Date(now.getFullYear(), now.getMonth() - 3, 1);
        break;
      case "this_year":
        from = new Date(now.getFullYear(), 0, 1);
        break;
      case "last_year":
        from = new Date(now.getFullYear() - 1, 0, 1);
        break;
    }

    return this.toIsoString(from);
  }

  getTo(selection) {
    var now = new Date();
    var to = undefined;

    switch(selection) {
      case "this_month":
        to = now;
        break;
      case "last_month":
        to = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "last_three_months":
        to = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "this_year":
        to = now;
        break;
      case "last_year":
        to = new Date(now.getFullYear(), 0, 0);
        break;
    }

    return this.toIsoString(to);
  }

  toIsoString(localDate) {
    if(localDate) {
      var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = (new Date(localDate - tzoffset)).toISOString();
      return localISOTime.slice(0,10);
    }
  }

  getGrossTotalInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);
    
    var total = 0;
    for(const invoice of invoicesOfType) {
      total += invoice.total_gross_amount;
    }

    return total;    
  }

  getNetTotalInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);

    var total = 0;
    for(const invoice of invoicesOfType) {
      total += invoice.total_net_amount;
    }

    return total;    
  }

  getInvoicesOfType(type) {
    if(!this.state.invoices) {
      return [];
    }
    return this.state.invoices.filter((invoice) => invoice.type === type);
  }
 
  getCountOfInvoiceType(type) {
    var invoicesOfType = this.getInvoicesOfType(type);
    if(!invoicesOfType) {
      return "0";
    }
    return invoicesOfType.length.toString();
  }

  getGrossProfit() {
    return this.getGrossTotalInvoiceType("sale") - this.getGrossTotalInvoiceType("purchase");
  }

  getNetProfit() {
    return this.getNetTotalInvoiceType("sale") - this.getNetTotalInvoiceType("purchase");
  }

  getGrossProfitClass() {
    if(this.getGrossProfit() > 0) {
      return "value-positive";
    }
    if(this.getGrossProfit() < 0) {
      return "value-negative";
    }
    return "";
  }

  getNetProfitClass() {
    if(this.getNetProfit() > 0) {
      return "value-positive";
    }
    if(this.getNetProfit() < 0) {
      return "value-negative";
    }
    return "";
  }

  getInvoicesRows() {    
    if(!this.state.invoices) {
      return;
    }
    
    if(!this.state.invoices) {
      return { data: [], totalElements: 0 };
    }
    
    return { data: this.state.invoices, totalElements: this.state.invoices.length, ready: true };
  }

  getInvoicesHeaders() {
    var headers = [];
  
    headers.push({
      field: "resource",
      headerName: "Resource",
      minWidth: 150,
      filter: "agTextColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: TableUtil.getTextColumnFilters()
      },
      rowGroup: true,
      floatingFilter: true,
      sortable:  true,
      hide: true
    });
    
    headers.push(TableUtil.getTextColumn(
      "invoice_number",
      "Invoice number",
      (params) => {
        if(params.value) {          
          return <Link to={`/invoices/all/${params.data.id}`} target="_blank" rel="noopener noreferrer" >{params.value}</Link>;
        }
        return "";
      }
    ));
    
    headers.push(TableUtil.getTextColumn("counterparty.name", "Counterparty"));

    headers.push(TableUtil.getSetColumn(
      "status",
      "Status",
      (params) => {
        if(params.value) {
          return <span className={"badge " + Mapper.toInvoiceStatusBadge(params.value)} >{Mapper.fromInvoiceStatus(params.value)}</span>;
        }
        return <span/>
      },
      Object.keys(Mapper.invoiceStatuses()),
      (value) => Mapper.fromInvoiceStatus(value)
    ));

    headers.push(TableUtil.getDateColumn(
      "invoice_date",
      "Invoice date",
      (params) => Converter.toDate(params.value),
    ));

    headers.push(TableUtil.getDateColumn(
      "due_date",
      "Due date",
      (params) => Converter.toDate(params.value),
    ));

    headers.push(TableUtil.getDateColumn(
      "sent_at",
      "Sent at",
      (params) => Converter.toDatetime(params.value),
    ));

    headers.push(TableUtil.getSetColumn(
      "type",
      "Type",
      (params) => Mapper.fromInvoiceType(params.value),
      Object.keys(Mapper.invoiceTypes()),
      (value) => Mapper.fromInvoiceType(value)
    ));

    headers.push({
      field: "total_gross_amount",
      headerName: "Total gross amount",
      minWidth: 150,
      cellRenderer: this.getAmount,
      filter: "agNumberColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: TableUtil.getNumberColumnFilters()
      },
      floatingFilter: true,
      sortable: true,
      aggFunc: 'sum',
      valueGetter: this.valueGetter
    });
    
    headers.push({
      field: "total_net_amount",
      headerName: "Total net amount",
      minWidth: 150,
      cellRenderer: this.getAmount,
      filter: "agNumberColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        filterOptions: TableUtil.getNumberColumnFilters()
      },
      floatingFilter: true,
      sortable: true,
      aggFunc: 'sum',
      valueGetter: this.valueGetter
    });
    
    return headers;
  }

  valueGetter(params) {
    if(!params.data) {
      return;
    }

    var factor = 1;
    if(params.data.type == 'purchase')  {
      factor = -1;
    }
    
    return params.data[params.colDef.field] * factor;
  }

  getAmount(params) {
    var className;
    if(params.value < 0) {
      className = "invoice-negative"
    }
    return <span className={className}>{Converter.toCurrency("eur", params.value)}</span>;
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/reports` + this.props.location.search}>Reports</Link></BreadcrumbItem>
            <BreadcrumbItem active>Invoices revenue</BreadcrumbItem>
          </Breadcrumb>
        
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Parameters</label>
                </div>
              </div>
            </div>

            <form autoComplete="off" onSubmit={this.createReport.bind(this)}>
              
              <div className="row row-small">
                <div className="col-md-4">  
                <FormControl
                  variant="outlined"
                  margin="dense"
                  fullWidth >
                  <InputLabel>Range *</InputLabel>
                  <Select
                    native
                    required
                    label="Range *"
                    name="range"
                    value={this.state.range.selection}
                    onChange={this.handleRangeChange.bind(this)} >
                      {Object.keys(Mapper.dateRanges()).map(status => {
                        return (<option key={"range_status_" + status} value={status}>{Mapper.fromDateRange(status)}</option>);
                      })}
                  </Select>
                </FormControl>
                </div>

                <div className="col-md-4">
                  <TextField
                    label="From"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="from"
                    value={this.state.range.from}
                    onChange={this.handleDateChange.bind(this)} />
                </div>

                <div className="col-md-4">
                  <TextField
                    label="To"
                    variant="outlined"
                    required
                    fullWidth
                    margin="dense"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="to"
                    value={this.state.range.to}
                    onChange={this.handleDateChange.bind(this)} />
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  <Autocomplete
                    key="counterparty"
                    options={this.state.accounts}
                    groupBy={this.firstAccountLetter.bind(this)}
                    getOptionLabel={(option) => this.getCounterpartyOptionLabel(option)}
                    value={this.state.counterparty}
                    onChange={this.handleCounterpartySelectionChanged.bind(this)}
                    renderInput={(params) => 
                      <TextField {...params}
                        label="Counterparty"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    }
                  />
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel>Show all invoices in group</InputLabel>
                    <Select
                      native
                      label="Show all invoices in group"
                      value={this.state.show_all_in_group}
                      name="show_all_in_group"
                      onChange={(e) => {this.handleChange("show_all_in_group", e)}} >
                        <option key={"show_all_in_group_empty"} value=""></option>
                        {Object.keys(Mapper.booleans()).map(type => {
                          return (<option key={"show_all_in_group_"+type} value={type}>{Mapper.fromBoolean(type)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Generate" />
                </div>
              </div>

            </form>
          </div>
        
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Invoices revenue</label>
                </div>
              </div>
            </div>
            <div className="row row-small">
              <div className="col-md-6 col-lg-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td width={200} className="header-text"></td>
                      <td width={200} className="header-text">Gross</td>
                      <td width={200} className="header-text">Net</td>
                      <td width={200} className="header-text">Invoices</td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Sale price</td>
                      <td>{Converter.toCurrency("eur", this.getGrossTotalInvoiceType("sale"))}</td>
                      <td>{Converter.toCurrency("eur", this.getNetTotalInvoiceType("sale"))}</td>
                      <td>{this.getCountOfInvoiceType("sale")}</td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text">Purchase price</td>
                      <td>{Converter.toCurrency("eur", this.getGrossTotalInvoiceType("purchase"))}</td>
                      <td>{Converter.toCurrency("eur", this.getNetTotalInvoiceType("purchase"))}</td>
                      <td>{this.getCountOfInvoiceType("purchase")}</td>
                    </tr>
                    <tr>
                      <td width={200} className="header-text-bold">Profit</td>
                      <td>{<span className={this.getGrossProfitClass()}><b>{Converter.toCurrency("eur", this.getGrossProfit())}</b></span>}</td>
                      <td>{<span className={this.getNetProfitClass()}><b>{Converter.toCurrency("eur", this.getNetProfit())}</b></span>}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Invoices overview</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm">
                <RowDataTable
                  name="invoices_revenue_table_state"
                  rowData={this.getInvoicesRows()}
                  columnDefs={this.getInvoicesHeaders()}
                  overlayNoRowsTemplate={"There are no invoices"}
                />
              </div>
            </div>
          </div>
        
        </div>
      </div >
    );
  }
}

export default InvoicesRevenueReport;
